<template>
  <div class="card card-custom gutter-b p-5">
    <div class="py-2">
      <b-button
        class="float-right"
        variant="success"
        @click="$bvModal.show('add-pemateri-modal')"
        >Tambah Pemateri</b-button
      >
    </div>
    <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
      <table
        id="example"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width: 20px">No</th>
            <th>Nama pemateri</th>
            <th>Jabatan</th>
            <th>Instansi</th>
            <th style="width: 90px">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in pemateri" :key="row.id">
            <td></td>
            <td>{{ row.nama }}</td>
            <td>{{ row.jabatan }}</td>
            <td>{{ row.instansi }}</td>
            <td>
              <b-button
                @click="showModal(row.id)"
                size="sm"
                variant="warning"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Edit Pemateri"
              >
                <i class="flaticon-edit"></i>
              </b-button>
              <b-button
                @click="deleteData(row.id)"
                size="sm"
                class="m-1 pr-2"
                variant="danger"
                v-b-tooltip.hover
                title="Hapus"
                ><i class="flaticon2-trash"></i
              ></b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal EditPemateri -->
    <b-modal id="edit-pemateri-modal" title="Ubah Pemateri" @ok="submitData">
      <form @submit.prevent="submitData">
        <b-form-group label="Nama">
          <b-form-input v-model="form.nama"></b-form-input>
        </b-form-group>

        <b-form-group label="Jabatan">
          <b-form-input v-model="form.jabatan"></b-form-input>
        </b-form-group>

        <b-form-group label="Instansi">
          <b-form-input v-model="form.instansi"></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <!-- Modal code -->
    <b-modal id="add-pemateri-modal" title="Tambah Pemateri" @ok="submitData">
      <b-form @submit="submitData">
        <b-form-group label="Nama">
          <b-form-input v-model="form.nama"></b-form-input>
        </b-form-group>

        <b-form-group label="Jabatan">
          <b-form-input v-model="form.jabatan"></b-form-input>
        </b-form-group>

        <b-form-group label="Instansi">
          <b-form-input v-model="form.instansi"></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import Swal from "sweetalert2";

export default {
  name: "pemateri-bmtk",
  components: {
    "v-select": VueSelect,
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      pemateri: [],
      role: "admin_bimtek",
      form: {},
    };
  },
  methods: {
    getdata() {
      $("#example").DataTable().destroy();
      this.loaddata();
    },
    loaddata() {
      this.pemateri = [];
      this.axios
        .get(this.url + "/profil_pemateri", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.pemateri = response.data.data;
          this.initDatatable();
        });
    },

    submitData() {
      if (this.form.id == null) {
        this.axios
          .post(this.url + "/profil_pemateri", this.form, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.$bvModal.hide("add-pemateri-modal");
            this.form = {};
            this.getdata();
            Swal.fire("Berhasil!", "Data berhasil ditambahkan!", "success");
          })
          .catch((error) => {
            Swal.fire("Gagal!", "Ada masalah saat menambahkan data.", "error");
          });
      } else {
        this.axios
          .put(this.url + "/profil_pemateri/" + this.form.id, this.form, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.$bvModal.hide("edit-pemateri-modal");
            this.form = {};
            this.getdata();
            Swal.fire("Tebarui!", "Data sukses diperbarui!", "success");
          })
          .catch((error) => {
            Swal.fire(
              "Failed!",
              "There was a problem updating the data.",
              "error"
            );
          });
      }
    },

    showModal(id) {
      const pemateri = this.pemateri.find((p) => p.id === id);
      if (pemateri) {
        this.form = pemateri;
      }
      this.$bvModal.show("edit-pemateri-modal");
    },

    deleteData(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(this.url + "/profil_pemateri/" + id, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              Swal.fire({
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                icon: "success",
              });
              this.getdata();
            });
        }
      });
    },

    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },
  },
  mounted() {
    this.getdata();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pemateri Bimtek" }]);
  },
};
</script>
